import Request from "src/request";
import { environment } from "src/variables";
// Components
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import {
  Card,
  Input,
  Button,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  FormGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  CircularProgress,
  FormLabel,
} from "@material-ui/core";
// Styles
import { FormWrapper, ButtonWrapper, Spacer } from "src/app/App.styles";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const asset: any = {
  fields: {},
  errors: {},
  request: {
    url: "",
  },
};

const setFormData = (fields: any) => {
  return Object.keys(fields).map((key) => {
    if (fields[key].type === "sub-form") {
      Object.keys(fields[key]).map((sub) => {
        if (fields[key][sub].maxLength) {
          fields[key][sub].format = "";
          new Array(fields[key][sub].maxLength).fill("#").forEach((length: any) => (fields[key][sub].format += length));
        }
      });
      return {
        id: key,
        label: fields[key].label,
        type: "subForm",
        action: fields[key].action,
        hidden: fields[key].hidden,
        subFields: fields[key],
      };
    }
    if (fields[key].type === "checkbox-group") {
      return {
        id: key,
        label: fields[key].label,
        type: "checkboxGroup",
        quantity: fields[key].quantity,
        checkbox: fields[key].checkbox,
        required: fields[key].required,
      };
    }
    if (!fields[key].format && fields[key].maxLength) {
      fields[key].format = "";
      new Array(fields[key].maxLength).fill("#").forEach((length: any) => (fields[key].format += length));
    }
    return {
      id: key,
      label: fields[key].label,
      value: fields[key].value,
      type: fields[key].type,
      options: fields[key].options,
      hidden: fields[key].hidden,
      maxLength: fields[key].maxLength,
      required: fields[key].required,
      mask: fields[key].mask,
      format: fields[key].format,
      prefix: fields[key].prefix,
      thousandSeparator: fields[key].thousandSeparator,
    };
  });
};

const setFieldData = (fields: any) => {
  const setFields: any = [];
  Object.keys(fields).map((key) => {
    if (asset.fields[key].type === "sub-form") {
      Object.keys(asset.fields[key]).map((subKey: any) => {
        if (typeof asset.fields[key][subKey] === "string") return;
        setFields[`${key}_${subKey}`] = asset.fields[key][subKey];
        return;
      });
    }
    if (asset.fields[key].type === "checkbox-group") {
      Object.keys(asset.fields[key].checkbox).map((box: any) => {
        if (typeof asset.fields[key].checkbox[box] === "string") return;
        setFields[`${key}_checkbox_${box}`] = asset.fields[key].checkbox[box];
        return;
      });
    }
    setFields[key] = asset.fields[key];
  });

  Object.keys(setFields).map((id) => {
    const [parent, child, grand] = id.split("_");
    if (grand) {
      const grandField: any = asset.fields[parent][child][grand];
      grandField.id = id;
      grandField.value = "";
      grandField.custom.id = id + "_custom";
      grandField.custom.value = "";
      grandField.checked = grandField.checked;
      return;
    }
    if (child) {
      const childField: any = asset.fields[parent][child];
      if (typeof childField === "boolean") return;
      childField.id = id;
      childField.value = "";
      childField.path = childField.path;
      return;
    }
    asset.fields[parent].id = id;
    asset.fields[parent].value = "";
  });

  return fields;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: "100%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    card: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      width: "-webkit-fill-available",
      alignContent: "center",
      padding: "0px 10px",
      borderRadius: "unset",
    },
    sub: {
      top: "38px",
      width: "87vw",
      maxWidth: "520px",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    subHeader: {
      background: "#ffff",
      display: "flex",
      justifyContent: "space-between",
      padding: "7px 0px",
      fountSize: "14px",
    },
    label: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    telLabel: {
      position: "relative",
    },
    telPosition: {
      position: "relative",
      transform: "translate(0, 1.5px) scale(0.75)",
      transformOrigin: "top left",
    },
    subLabel: {
      position: "relative",
      padding: "10px 8px",
      color: "#000000",
      fontFamily: "Georgia, serif",
      fontSize: "20px",
    },
    checkboxLabel: {
      fontFamily: "Georgia, serif",
      fontSize: "20px",
      padding: "16px 0",
    },
    checkboxOption: {
      width: "30%",
      padding: "0 20px",
      position: "relative",
      top: "30px",
    },
    emptyCheckbox: {
      height: "48px",
    },
    formControl: {
      maxWidth: "700px",
      margin: theme.spacing(0.02),
    },
    buttonContainer: {
      minWidth: "100%",
      marginTop: theme.spacing(5),
    },
    spinnerContainer: {
      position: "absolute",
      index: "1",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    submitButton: {
      float: "right",
    },

    success: {
      color: "#4caf50",
      position: "relative",
      top: 30,
    },
    error: {
      color: "#f44336",
    },
    noError: {
      padding: "10px",
    },
  })
);

const Contact = () => {
  const [fields, setFields]: any[] = useState(setFormData(asset.fields));
  const [errors, setErrors]: any = useState(asset.errors);
  const [loading, setLoading]: any = useState(false);
  const [success, setSuccess]: any = useState(false);
  const classes = useStyles();
  const url = environment.apiPath + asset.request.url + environment.source;

  const clearContactForm = () => {
    asset.fields = setFieldData(asset.fields);
    setFields(() => setFormData(asset.fields));
  };

  const handleSubField = (object: any, key: string) => {
    let str: string = "";
    const subs: any[] = [];
    Object.keys(asset.fields[key]).map((subKey) => {
      if (asset.fields[key][subKey].value) subs.push(asset.fields[key][subKey]);
    });
    subs.forEach((sub) => {
      let value: string = "";
      if (typeof sub.value === "object") value = sub.value.value;
      else value = sub.value;
      str += subs[subs.length - 1].id === sub.id ? value : value + ", ";
    });
    return Object.assign(object, { [key]: str });
  };

  const handleSubError = (newErrors: any, key: string) => {
    if (asset.fields[key].hidden) return;
    Object.keys(asset.fields[key]).map((subKey: any) => {
      if (asset.fields[key][subKey].required && !asset.fields[key][subKey].value) {
        newErrors[`${key}_${subKey}`] = {
          error: true,
          message: `${asset.fields[key][subKey].label} is required!`,
        };
      }
      const poBox = new RegExp("\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b", "i");
      if (subKey === "address" && poBox.test(asset.fields[key][subKey].value.toLowerCase())) {
        newErrors[`${key}_${subKey}`] = {
          error: true,
          message: `${asset.fields[key][subKey].label} can not be a po box!`,
        };
      }
    });
  };

  const handleCheckbox = (object: any, key: string) => {
    return Object.assign(object, { [key]: asset.fields[key].selected ? "Yes" : "No" });
  };

  const handleBoxGroup = (object: any, key: string) => {
    let str: string = "";
    const checkboxes: any[] = [];
    Object.keys(asset.fields[key].checkbox).map((checkboxType: any) => {
      const checkbox: any = asset.fields[key].checkbox[checkboxType];
      if (!checkbox.selected) return;
      if (checkbox.custom.value)
        str = `${checkbox.label} order of ${checkbox.value}, message from customer: ${checkbox.custom.value}`;
      else str = `${checkbox.label} order of ${checkbox.value}.`;
      checkboxes.push(str);
    });
    return Object.assign(object, { [`${key}s`]: checkboxes });
  };

  const handleBoxError = (newErrors: any, key: string) => {
    let checked = 0;
    const error = {
      error: true,
      message: "",
    };

    Object.keys(asset.fields[key].checkbox).forEach((box: any) =>
      asset.fields[key].checkbox[box].selected ? checked++ : null
    );

    if (checked < asset.fields[key].require) {
      error.message = `${asset.fields[key].require.toString()} or more ${asset.fields[key].label} is required!`;
      newErrors[key] = error;
    }
    if (
      Object.keys(asset.fields[key].checkbox).some(
        (box: any) =>
          asset.fields[key].checkbox[box].value === "Custom" && !asset.fields[key].checkbox[box].custom.value
      )
    ) {
      error.message = `Missing custom ${asset.fields[key].label} Quantity!`;
      newErrors[key] = error;
    }
  };

  const checkboxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [key, checkbox, type, subType] = event.target.name.split("_");
    let newFields = [...fields];
    const newCheckboxGroup = newFields.reduce((object, newField) => ({ ...object, [newField.id]: newField }), {})[key];
    const newCheckbox = newCheckboxGroup.checkbox[type];
    const assetCheckbox = asset.fields[key].checkbox[type];
    newCheckbox.selected = event.target.checked;
    if (subType === "custom") {
      newCheckbox.custom.value = event.target.value;
      assetCheckbox.custom.value = event.target.value;
    }
    if (subType !== "custom" && !event.target.checked) {
      newCheckbox.value = event.target.value;
      assetCheckbox.value = event.target.value;
    }
    if (!newCheckbox.value && event.target.checked) {
      newCheckbox.value = newCheckbox.quantity.options[0];
      assetCheckbox.value = newCheckbox.quantity.options[0];
    }

    setFields(newFields);

    let restErrors = { ...errors };
    if (restErrors[key]) restErrors[key] = null;

    setErrors(restErrors);
  };

  const fieldChanged = (event: any) => {
    setSuccess(false);

    let newFields = [...fields];
    const value = event.target.value;
    const checked = event.target.checked;
    const [key, subKey] = event.target.name.split("_");
    const newField = newFields.reduce((object, newField) => ({ ...object, [newField.id]: newField }), {});

    if (subKey) {
      asset.fields[key][subKey].value = value;
      newField[key].subFields[subKey].value = value;
      labelUpdate(event);
    } else {
      asset.fields[key].selected = checked;
      newField[key].selected = checked;
      asset.fields[key].value = value;
      newField[key].value = value;
    }

    if (asset.fields[key].paths && asset.fields[key].paths.length) {
      asset.fields[key].paths.forEach((path: string) => {
        asset.fields[path].hidden = checked ? asset.fields[key].change : !asset.fields[key].change;
        newField[path].hidden = checked ? asset.fields[key].change : !asset.fields[key].change;
      });
    }

    setFields(newFields);

    let restErrors = { ...errors };
    if (restErrors[key]) restErrors[key] = null;
    if (restErrors[key + "_" + subKey]) restErrors[key + "_" + subKey] = null;
    setErrors(restErrors);
  };

  const labelUpdate = (event: any) => {
    const [key] = event.target.name.split("_");
    let subFields: any[] = [];
    subFields = Object.keys(asset.fields[key]).map((subKey) => asset.fields[key][subKey]);
    subFields.map((subField: any) => {
      if (subField.type === "label")
        subField.value = subField.path.reduce((obj: any, name: string) => obj && obj[name], asset.fields);
    });
  };

  const Field = (props: any) => {
    const field = props.field;
    return (
      <React.Fragment key={field.id}>
        {field.type === "text-field" ? <Spacer height={32} /> : null}
        <Card className={classes.card}>
          {field.type === "checkbox" ? (
            <FormControl
              key={field.id}
              className={`${classes.formControl} ${classes.fullWidth}`}
              error={!!errors[field.id]}
            >
              <FormGroup row>
                <FormControlLabel
                  key={field.id}
                  label={field.label}
                  labelPlacement={field.placement ? field.placement : "end"}
                  control={
                    <Checkbox
                      id={field.id}
                      name={field.id}
                      checked={field.checked}
                      onChange={(e: any) => fieldChanged(e)}
                    />
                  }
                />
              </FormGroup>
              <FormHelperText id={`${field.id}-${field.type}`} className={!errors[field.id] ? classes.noError : ""}>
                {errors[field.id] ? errors[field.id].message : ""}
              </FormHelperText>
            </FormControl>
          ) : null}
          {field.type === "checkboxGroup" ? (
            <div className={`${classes.column} ${classes.fullWidth}`}>
              <div key={`${field.id}_checkbox`} className={`${classes.row} ${classes.fullWidth}`}>
                <FormControl key={field.id} className={`${classes.formControl}`} error={!!errors[field.id]}>
                  <FormLabel component="legend" className={classes.checkboxLabel}>
                    {field.label}
                  </FormLabel>
                  <FormGroup key={`${field.id}_checkbox`}>
                    {Object.keys(field.checkbox).map((box: any) =>
                      box ? (
                        <FormControlLabel
                          key={field.checkbox[box].id}
                          label={field.checkbox[box].label}
                          labelPlacement={field.checkbox[box].placement ? field.checkbox[box].placement : "end"}
                          control={
                            <Checkbox
                              id={field.checkbox[box].id}
                              name={field.checkbox[box].id}
                              checked={field.checkbox[box].checked}
                              onChange={(e: any) => checkboxChanged(e)}
                            />
                          }
                        />
                      ) : null
                    )}
                  </FormGroup>
                </FormControl>
                <div key={`${field.id}_select`} className={`${classes.column} ${classes.checkboxOption}`}>
                  {Object.keys(field.checkbox).map((box: any) =>
                    box ? (
                      <FormControl
                        key={`${field.checkbox[box].id}_quantity`}
                        className={`${classes.formControl} ${classes.fullWidth}`}
                        error={!!errors[field.id]}
                      >
                        <InputLabel id={`${field.checkbox[box].id}-label`}>
                          {`${field.checkbox[box].label} Quantity`}
                        </InputLabel>
                        <Select
                          labelId={`${field.checkbox[box].id}-label`}
                          id={`${field.checkbox[box].id}_quantity`}
                          name={`${field.checkbox[box].id}_quantity`}
                          value={field.checkbox[box].value}
                          label={`${field.checkbox[box].id}-quantity`}
                          onChange={(e: any) => {
                            checkboxChanged(e);
                          }}
                        >
                          {field.checkbox[box].quantity.options.map((option: any) => (
                            <MenuItem key={`${field.checkbox[box].id}-${option}-select`} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : null
                  )}
                </div>
                <div key={`${field.id}_custom`} className={`${classes.column} ${classes.checkboxOption}`}>
                  {Object.keys(field.checkbox).map((box: any) =>
                    field.checkbox[box].value === "5 + cord" ? (
                      <FormControl
                        key={`${field.checkbox[box].id}_quantity`}
                        className={`${classes.formControl} ${classes.fullWidth}`}
                        error={!!errors[field.id]}
                      >
                        <InputLabel id={`${field.checkbox[box].id}-label`}>Custom Quantity</InputLabel>
                        <Input
                          type={field.checkbox[box].custom.type}
                          id={field.checkbox[box].custom.id}
                          name={field.checkbox[box].custom.id}
                          value={field.checkbox[box].custom.value}
                          onChange={(e: any) => checkboxChanged(e)}
                          inputProps={{
                            "aria-label": "description",
                            minLength: field.checkbox[box].custom.minLength,
                            maxLength: field.checkbox[box].custom.maxLength,
                          }}
                        />
                      </FormControl>
                    ) : (
                      <div key={`${field.checkbox[box].id}_empty`} className={classes.emptyCheckbox} />
                    )
                  )}
                </div>
              </div>
              <FormHelperText
                id={`${field.id}-${field.type}`}
                className={!errors[field.id] ? classes.noError : classes.error}
              >
                {errors[field.id] ? errors[field.id].message : ""}
              </FormHelperText>
            </div>
          ) : null}
          {field.type === "text" ? (
            <FormControl
              key={field.id}
              className={`${classes.formControl} ${classes.fullWidth}`}
              error={!!errors[field.id]}
            >
              <InputLabel htmlFor={field.id}>{field.label}</InputLabel>
              <Input
                type={field.type}
                id={field.id}
                name={field.id}
                value={field.value}
                onChange={(e: any) => fieldChanged(e)}
                inputProps={{ "aria-label": "description", minLength: field.minLength, maxLength: field.maxLength }}
                aria-describedby={`${field.id}-${field.type}`}
              />
              <FormHelperText id={`${field.id}-${field.type}`} className={!errors[field.id] ? classes.noError : ""}>
                {errors[field.id] ? errors[field.id].message : ""}
              </FormHelperText>
            </FormControl>
          ) : null}
          {field.type === "number" ? (
            <FormControl
              key={field.id}
              className={`${classes.formControl} ${classes.fullWidth}`}
              error={!!errors[field.id]}
            >
              <InputLabel htmlFor={field.id} className={field.value ? classes.telPosition : classes.telLabel}>
                {field.label}
              </InputLabel>
              <NumberFormat
                {...props}
                format={field.format}
                prefix={field.prefix}
                mask={field.mask}
                thousandSeparator={field.thousandSeparator}
                id={field.id}
                name={field.id}
                type="text"
                value={field.value}
                customInput={TextField}
                onChange={(e: any) => fieldChanged(e)}
              />
              <FormHelperText id={`${field.id}-${field.type}`} className={!errors[field.id] ? classes.noError : ""}>
                {errors[field.id] ? errors[field.id].message : ""}
              </FormHelperText>
            </FormControl>
          ) : null}
          {field.type === "tel" ? (
            <FormControl
              key={field.id}
              className={`${classes.formControl} ${classes.fullWidth}`}
              error={!!errors[field.id]}
            >
              <InputLabel htmlFor={field.id} className={field.value ? classes.telPosition : classes.telLabel}>
                {field.label}
              </InputLabel>
              <NumberFormat
                {...props}
                format="+1 (###) ###-####"
                mask="_"
                id={field.id}
                name={field.id}
                type={field.type}
                value={field.value}
                customInput={TextField}
                onChange={(e: any) => fieldChanged(e)}
              />
              <FormHelperText id={`${field.id}-${field.type}`} className={!errors[field.id] ? classes.noError : ""}>
                {errors[field.id] ? errors[field.id].message : ""}
              </FormHelperText>
            </FormControl>
          ) : null}
          {field.type === "select" ? (
            <FormControl
              key={field.id}
              className={`${classes.formControl} ${classes.fullWidth}`}
              error={!!errors[field.id]}
            >
              <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>
              <Select
                labelId={`${field.id}-label`}
                id={field.id}
                name={field.id}
                value={field.value}
                label={field.id}
                onChange={(e: any) => {
                  fieldChanged(e);
                }}
              >
                {field.options.map((option: any) => (
                  <MenuItem key={`${field.id}-${option.value}-select`} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id={`${field.id}-${field.type}`} className={!errors[field.id] ? classes.noError : ""}>
                {errors[field.id] ? errors[field.id].message : ""}
              </FormHelperText>
            </FormControl>
          ) : null}
          {field.type === "text-field" ? (
            <FormControl key={field.id} className={`${classes.formControl} ${classes.fullWidth}`}>
              <TextField
                type={field.type}
                id={field.id}
                name={field.id}
                label={field.label}
                value={field.value}
                multiline
                rows={3}
                onChange={(e: any) => fieldChanged(e)}
                error={!!errors[field.id]}
                aria-describedby={`${field.id}-text-field`}
                inputProps={{
                  maxLength: field.maxLength,
                }}
              />
              <FormHelperText id={`${field.id}-text-field`}>
                {errors[field.id] ? errors[field.id].message : field.maxLength - field.value.length}
              </FormHelperText>
            </FormControl>
          ) : null}
        </Card>
      </React.Fragment>
    );
  };

  const addField = (oldField: any) => {
    const newField = JSON.parse(JSON.stringify(oldField));
    newField.value = "";
    delete newField.action;
    delete newField.subFields.action;
    let index = 1;
    fields.forEach((field: any) => (field.id.includes(newField.id) ? index++ : null));
    newField.id = newField.id + index.toString();
    newField.label = newField.label + " " + index.toString();
    const assetField: any = { id: newField.id, label: newField.label, type: "sub-form", value: "" };
    Object.keys(newField.subFields).map((field) => {
      if (!newField.subFields[field].id) return;
      newField.subFields[field].id = newField.id + "_" + field;
      assetField[field] = newField.subFields[field];
    });
    asset.fields[newField.id] = assetField;
    fields.splice(
      fields.findIndex((field: any) => field.id === oldField.id + (index - 1 > 1 ? (index - 1).toString() : "")) + 1,
      0,
      newField
    );
    const newFields = [...fields];
    console.log("add", asset.fields);
    setFields(newFields);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (loading) return;
    setLoading(true);
    let payload: any = { ...asset.fields };
    delete payload.companies;
    const body = Object.keys(payload).reduce((object: any, key: any) => {
      switch (asset.fields[key].type) {
        case "sub-form":
          return handleSubField(object, key);
          break;
        case "checkbox":
          return handleCheckbox(object, key);
          break;
        case "checkbox-group":
          return handleBoxGroup(object, key);
          break;
        default:
          return Object.assign(object, { [key]: asset.fields[key].value });
      }
    }, {});
    if (body.name) body.name = body.name.replace(",", "");
    if (body.contactTime) body.contactTime = body.contactTime.value;
    let newErrors = { ...errors };
    Object.keys(asset.fields).forEach((key: any) => {
      if (asset.fields[key].type === "sub-form") {
        handleSubError(newErrors, key);
      } else if (asset.fields[key].type === "checkbox-group") {
        handleBoxError(newErrors, key);
      } else if (key === "phone" && asset.fields[key].value[4] === "1") {
        newErrors[key] = {
          error: true,
          message: `${asset.fields[key].label} area code must start with something other then 1!`,
        };
      } else if (asset.fields[key].required && !asset.fields[key].value) {
        newErrors[key] = {
          error: true,
          message: `${asset.fields[key].label} is required!`,
        };
      } else {
        newErrors[key] = null;
      }
    });
    if (newErrors) setErrors(newErrors);
    asset.errors = errors;

    const hasError = Object.keys(newErrors)
      .map((key) => newErrors[key])
      .some((error) => error);

    if (newErrors && !hasError) setErrors({});

    if (hasError) {
      setLoading(false);
      return;
    }

    Request(url, { method: "POST", body: JSON.stringify(body) }).subscribe(
      (result: any) => {
        clearContactForm();
        setLoading(false);
        setSuccess(result.message);
      },
      (error: any) => {
        fields.forEach((field: any) => {
          if (error.errors[field.id]) {
            newErrors[field.id] = {
              error: true,
              message: error.errors[field.id],
            };
          }
        });
        setErrors(newErrors);
        setLoading(false);
      }
    );
  };

  return (
    <form id="contact" className={`form ${classes.card}`} onSubmit={handleSubmit}>
      <FormWrapper>
        {fields.map((field: any) => {
          if (field.type === "subForm" && !field.hidden) {
            return (
              <div key={field.id} className={classes.sub}>
                {field.label ? <Spacer height={32} /> : null}
                <div className={field.label ? classes.subHeader : ""}>
                  <InputLabel className={classes.subLabel} htmlFor={field.id}>
                    {field.label}
                  </InputLabel>
                  {typeof field.action === "object" ? (
                    <Button
                      key={field.action.id}
                      id={field.action.id}
                      name={field.action.id}
                      type={field.action.type}
                      variant="outlined"
                      color="default"
                      onClick={() => addField(field)}
                    >
                      {field.action.label}
                    </Button>
                  ) : null}
                </div>
                {Object.keys(field.subFields).map((key: any) => {
                  if (typeof field.subFields[key] === "object") return Field({ field: field.subFields[key] });
                })}
                <Spacer height={32} />
              </div>
            );
          } else if (!field.hidden) {
            return Field({ field: field });
          }
        })}
        <ButtonWrapper className={classes.buttonContainer}>
          {success && <Typography className={classes.success}>{success}</Typography>}
          <Button variant="contained" color="secondary" type="submit" className={classes.submitButton}>
            {loading && <CircularProgress size={36} className={classes.spinnerContainer} color="primary" />}
            Submit
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </form>
  );
};

export default function Form(props: any) {
  asset.request.url = props.urlRequest;
  asset.fields = props.formFields;
  asset.fields = setFieldData(asset.fields);
  return (
    <div>
      <Contact />
    </div>
  );
}
