import { environment } from "src/variables";
// Styles
import { Wrapper } from "src/app/App.styles";
import { createStyles, createTheme, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import Header from "src/app/components/Header";

const theme = createTheme({
  palette: {
    primary: {
      main: "#D13E3A",
    },
    secondary: {
      main: "#44599e",
    },
  },
});

const banner: any = {
  fontFamily: "Georgia, serif",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontSize: "50px",
  background: "linear-gradient(to right," + theme.palette.primary.main + ", " + theme.palette.secondary.main + ")",
};

const subText: any = {
  section: {
    margin: "24px 0",
  },
  header: {
    fontWeight: "bold",
  },
  text: {
    textAlign: "left",
    fontFamily: "Georgia, serif",
    fontSize: "24px",
    margin: "5px 0",
  },
};

let privacy: string[][] = [];

privacy[0] = [
  "Mohave Service <span style='font-weight: 400;'>Privacy and Policy</span>" + "<br/>",
  "At Mohave Service, we respect your privacy. By opting into our SMS messaging service, you agree to the following terms regarding how we handle your data:",
];

privacy[1] = [
  "1. Data Collection: ",
  "We will collect your name, email address, and mobile phone number when you sign up for SMS updates. This information will be gathered through paper documents provided to customers at our physical location. If customers request an electronic version, it will be shared via email only with their explicit consent.",
];

privacy[2] = [
  "2. Data Usage: ",
  "We use your data solely for sending schedule and confirm appointments and to provide customer assistance.",
];

privacy[3] = ["3. Data Security: ", "We protect your data with secure storage measures to prevent unauthorized access"];

privacy[4] = [
  "4. Data Retention: ",
  "We retain your information as long as you are subscribed to our SMS service. You may request deletion at any time.",
];
privacy[5] = [
  "5. Message and Data Rates may apply: ",
  "Your mobile carrier may charge fees for sending or receiving text messages, especially if you do not have an unlimited texting or data plan. Messages are recurring and message frequency varies. ",
];
privacy[6] = ["6. Contact: ", "Mohave Service at (435) 691-4901 for HELP or to STOP receiving messages."];
privacy[7] = [
  "7. Opt-Out: ",
  "You can opt out of the SMS list at any time by texting, emailing, or replying STOP to (435) 691-4901. After unsubscribing, you will receive a final SMS to confirm you have unsubscribed and we will remove your number from our list within 24 hours. You can send HELP for additional assistance, and you will receive a text including our phone number, email address, and website. We are here to help you. ",
];
privacy[8] = [
  "8. Non-Sharing Clauses: ",
  "Mohave Service does not share your data with third parties for marketing purposes. Mohave Service does not sell, rent, or share the collected mobile numbers or mobile opt-in information with anyone. ",
];

export default function Privacy() {
  const useStyles = makeStyles((theme: any) =>
    createStyles({
      background: {
        color: "#FFFFF",
      },
      foreground: {
        minWidth: "100%",
        minHeight: "100%",
      },
      table: {
        maxWidth: 725,
      },
      text: {
        textAlign: "center",
        maxWidth: "715px",
        fontFamily: "Georgia, serif",
      },
      section: {
        padding: "0 48px",
      },
    })
  );

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Header text={environment.siteTitle} style={banner} />
      </Wrapper>
      <Typography className={classes.section} component={"div"} variant={"body2"}>
        {privacy.map((term: any[], index: number) => {
          return (
            <p key={"privacy_" + index} style={(subText.text, subText.section)}>
              {term.map((condition: string, index: number) => {
                return (
                  <span
                    key={"condition_" + index}
                    style={index === 0 || index === 2 ? subText.header : null}
                    dangerouslySetInnerHTML={{ __html: condition }}
                  ></span>
                );
              })}
            </p>
          );
        })}
      </Typography>
    </ThemeProvider>
  );
}
