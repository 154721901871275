import { environment } from "src/variables";
// Components
import React, { useEffect } from "react";
import Form from "src/app/components/Form";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Link } from "react-router-dom";
// Styles
import { Wrapper, Spacer } from "src/app/App.styles";
import { ThemeProvider, createStyles, createTheme, makeStyles, Theme } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#D13E3A",
    },
    secondary: {
      main: "#44599e",
    },
    text: {
      primary: "#201d1e",
    },
  },
});

const formFields: any = {
  name: { label: "Full name / Business name", type: "text", required: true },
  phone: {
    label: "Phone number",
    type: "tel",
    required: true,
    minLength: 10,
    maxLength: 10,
  },
  email: { label: "Email", type: "text", required: false },
  message: {
    label: "Message",
    type: "text-field",
    required: false,
    maxLength: 250,
  },
  agree: {
    label: "Yes, I agree to receive text messages from Mohave Service sent from (435) 691-4901",
    type: "checkbox",
  },
};

export default function OptIn() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      background: {
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      foreground: {
        minWidth: "100%",
        minHeight: "100%",
      },
      form: {
        color: "#ffffff",
      },
      disclaimers: {
        width: "80%",
      },
    })
  );

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const setCookie = (name: string) => {
    const optSelected = getCookie();
    document.cookie = optSelected ? name : "temp_opt";
  };
  const getCookie = () => (document.cookie ? document.cookie : null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (name: string) => {
    setCookie(name);
    setOpen(false);
  };

  useEffect(() => {
    const optSelected = getCookie();
    if (!optSelected || optSelected === "temp_opt") handleOpen();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Button autoFocus color="inherit" onClick={() => handleClose("min_cookie_opt")}>
              Accept essential cookies
            </Button>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div"></Typography>
            <Button autoFocus color="inherit" onClick={() => handleClose("all_cookie_opt")}>
              Accept all
            </Button>
          </Toolbar>
        </AppBar>
        <Spacer height={32} />
        <Wrapper>
          <Typography sx={{ ml: 2, flex: 1 }} component="p">
            We use cookies to improve your experience on our site. By using our site, you consent to the use of cookies.
            Rejecting cookies will prevent non-essential cookies from loading.
          </Typography>
        </Wrapper>
        <Spacer height={32} />
        <div className={classes.background}>
          <section className={classes.foreground}>
            <Form className={classes.form} formFields={formFields} urlRequest="/public/contact_email" />
            <Wrapper>
              <p className={classes.disclaimers}>
                Do you Agree to receive text messages from Mohave Service sent from (435) 691-4901. Message frequency
                varies and may include confirm and schedule appointments and to provide customer support. Message and
                data rates may apply. Reply STOP at any time to opt-out of further text message communications. For
                assistance, reply HELP or contact support at: (435) 691-4901
              </p>
              <p className={classes.disclaimers}>
                See our <Link to="/privacy">Privacy Policy</Link> for details on how we handle your information.
              </p>
            </Wrapper>
          </section>
        </div>
      </Dialog>
    </ThemeProvider>
  );
}
