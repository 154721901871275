import styled from 'styled-components';
import styledTS from 'styled-components-ts';

export const Wrapper = styledTS<any>(styled.div)`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${props => props.color + '!important' || '#00000000!important'};
`;

export const FormWrapper = styledTS<any>(styled.div)`
    width: 80%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${props => props.color + '!important' || '#00000000!important'};
`;

export const ButtonWrapper = styled.div`
    display: inline-block;
    position: relative;
    width: 65%;
    min-height: 40px;
    max-width: 700px;
`;

export const Spacer = styledTS<any>(styled.div)`
    min-height: ${props => props.height + 'px' || 0};
`;